.button {
  background-color: #bb3036;
  border: #bb3036;
  border-style: solid;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button:hover {
  background-color: white;
  border: black;
  border-style: solid;
  color: black;
}

.floating-image {
  overflow: hidden;
  border-radius: 16px;
}
